<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新建技能' : '修改技能'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="技能名称" prop="name">
        <el-input v-model="formData.name" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="submit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import abilityApi from '@api/ability'
export default {
  name: 'FunctionAbilityCUDialog',
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('技能名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      test: '',
      value1: '',
      formData: {
        name: ''
      },
      abilityId: '',
      ruleValidate: {
        name: [
          { required: true, message: '技能名称不能为空' },
          { max: 50, message: '技能名称长度不能超过50个字符' },
          { min: 1, message: '技能名称长度不小于1个字符' },
          { validator: validateName, trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  methods: {
    open(mode) {
      this.visible = true
      this.mode = mode
    },
    submit() {
      if (this.mode) {
        this.createAbility()
      } else {
        this.updateAbility()
      }
    },
    createAbility() {
      let payload = {
        name: this.formData.name,
        parentId: 0
      }
      this.$refs.form.validate(val => {
        if (val) {
          this.loading = true
          abilityApi
            .addOrgAbility(payload)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('新建技能成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    updateAbility() {
      let payload = {
        value: this.formData.name,
        abilityId: this.abilityId
      }
      this.$refs.form.validate(val => {
        if (val) {
          this.loading = true
          abilityApi
            .modifyOrgAbility(payload)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('修改技能成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    update(mode, data) {
      this.visible = true
      this.mode = mode
      this.formData.name = data.name
      this.abilityId = data.id
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
      this.formData = { name: '' }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="less" scoped></style>
